var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Rezervacije',
          icon: 'tim-icons icon-badge',
          path: '/tickets',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Kalendar',
          icon: 'el-icon-date',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Dostupnost',
            // icon: 'el-icon-date',
            path: '/calendar',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Rezervacije',
            // icon: 'el-icon-date',
            path: '/calendarReservation',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Boksevi',
          icon: 'el-icon-takeaway-box',
          path: '/boxes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.pages'),
          icon: 'tim-icons icon-settings-gear-63',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.administration'),
            path: '/pages/administration',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.general'),
            path: '/pages/general',
          }}})],1)],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }